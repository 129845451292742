import React from 'react'
import Img from 'gatsby-image'

import styles from './hero.module.css'

export default ({ data, about }) => (
  <div className={styles.hero}>
    <img className={styles.heroImage} alt={data.name} src={data.image.file.url} />
    <h1 className={styles.heading}>{about.description}</h1>
    <p className={styles.subHeading}>{about.mediumUser}</p>
  </div>
)
