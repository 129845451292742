import React from 'react'
import Img from 'gatsby-image'

import styles from './logo.module.css'

export default ({ data }) => (
  <div className={styles.logo}>
    <img src={data.profile.file.url} alt="AdFoodz" className={data.logo} />
  </div>
)
