import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Logo from '../components/logo'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import favicon16 from '../../static/favicon-16x16.png';
import favicon32 from '../../static/favicon-32x32.png';
import favicon96 from '../../static/android-chrome-192x192.png';

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    const [about] = get(this, 'props.data.allContentfulAbout.edges')
    const showThankYou = this.props.location.search;

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <Helmet
            title={siteTitle}
            meta={[
              {
                name: "description",
                content: "A new and exciting food delivery app, made for local businesses, coming soon!",
              },
              {
                name: "keywords",
                content: "AdFoodz, delivery, app",
              },
            ]}
            link={[
              { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon16}` },
              { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` },
              { rel: "shortcut icon", type: "image/png", href: `${favicon96}` },
            ]}
          />
          <div className="wrapper__outer wrapper__outer--tight">
            <div className="wrapper">
              <Logo data={about.node} />
            </div>
          </div>
          <Hero data={author.node} about={about.node} />
          <div className="wrapper__outer">
            <div className="wrapper">
              {
                (showThankYou === '?sent=true')
                  ? <div className="notification notification--success">Thank you for registering your interest. We'll be in touch shortly.</div>
                  : <h2 className="section-headline">Please select from one of the options below to register your interest</h2>
              }
              <ul className="article-list">
                {posts.map(({ node }) => {
                  return (
                    <li key={node.slug}>
                      <ArticlePreview article={node} />
                    </li>
                  )
                })}
              </ul>
              <p className="cta">For more information please email: <a href="mailto:info@adfoodz.com.au">info@adfoodz.com.au</a></p>
              <div className="footer">&copy; 2020 {siteTitle}</div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          showCompanyName
          showSuburb
          showRestaurantLocation
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: SCALE
              background: "rgb:ffffff"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulAbout {
      edges {
        node {
          id
          aboutMe {
            childMarkdownRemark {
              html
            }
          }
          profile {
            file {
              url
            }
          }
          description
          mediumUser
        }
      }
    }
  }
`
