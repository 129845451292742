import React, { useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import styles from './form.module.css';

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default (props) => {
  const { company, suburb, restaurantLocation, id, formName } = props;
  const [success, setSuccess] = useState(false);  

  const handleSubmit = (event) => {
    event.preventDefault();
    const inputs = event.currentTarget.querySelectorAll('input');
    let name, phone, email, formtype, companyName, suburbName;
  
    inputs.forEach(input => {
      switch (input.name) {
        case 'name':
          name = input.value;
          break;
        case 'phone':
          phone = input.value;
          break;
        case 'email':
          email = input.value;
          break;
        case 'company':
          companyName = input.value;
          break;
        case 'suburb':
          suburbName = input.value;
          break;
        case 'formtype':
          formtype = input.value;
          break;
      }
    });
  
    const server = 'http://localhost:3000';

    let textString = `ADFOODZ REGISTRATION\nName: ${name}\nPhone: ${phone}\nEmail: ${email}`;
    let htmlString = `<p><b>ADFOODZ REGISTRATION ${formtype}</b></p><p><b>Name</b>: ${name}</p><p><b>Phone</b>: ${phone}</p><p><b>Email</b>: ${email}</p>`;

    if (companyName) {
      textString = textString + `Company Name: ${companyName}\n`;
      htmlString = htmlString + `<p><b>Company Name</b>: ${companyName}</p>`;
    }

    if (suburbName) {
      textString = textString + `Suburb: ${suburbName}\n`;
      htmlString = htmlString + `<p><b>Suburb</b>: ${suburbName}</p>`;
    }
  
    const params = {
      key: '123',
      text: textString,
      html: htmlString,
      subject: `AdFoodz Registration: ${formtype}`,
      from: `${name} <${email}>`,
      to: 'AdFoodz <info@adfoodz.com.au>',
    };

    const result = axios({
      method: 'post',
      url: server,
      params: qs.stringify(params),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    })
      .then(data => {
        if (data.status === 200 && data.data === 'Success!') {
          return true;
        } else {
          return false;
        }
      })
      .catch(err => {
        return false;
      });
  
    setSuccess(result);
  };

  return <div className={styles.form} id={id}>
    <form method="get" action="/mail">
      <label>
        Name
        <input type="text" name="name" id="name" required />
      </label>
      {
        company ?
          <label>
            Company Name
            <input type="text" name="company" id="company" required />
          </label>
        : ''
      }
      <label>
        Contact Number
        <input type="tel" name="phone" id="phone" required />
      </label>
      {
        suburb ?
          <label>
            Main delivery suburb area
            <input type="text" name="suburb" id="suburb" required />
          </label>
        : ''
      }
      {
        restaurantLocation ?
          <label>
            Suburb location of your restaurant / shop
            <input type="text" name="suburb" id="suburb" required />
          </label>
        : ''
      }
      <label>
        Email Address
        <input type="email" name="email" id="email" required />
      </label>
      {
        success 
          ? <div className="notification notification--success"><b>Thank you!</b> Your registration has been sent.</div>
          : ''
      }
      <button type="submit">Register</button>
      <input type="reset" value="Clear" />
      <input type="hidden" name="formtype" value={formName} />
    </form>
  </div>;
}
