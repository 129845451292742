import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Modal from 'react-modal';
import Form from '../components/form'

import styles from './article-preview.module.css'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

export default ({ article }) => {
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }

  
  const formId = getRandomInt(100000);
  const appElement = `form-${formId}`;
  // console.log(`AP[${formId}]: Suburb`, article.showSuburb);
  // console.log(`AP[${formId}]: Company`, article.showCompanyName);
  
  if (typeof(window) !== 'undefined') {
    Modal.setAppElement('body')
    // Modal.setAppElement(`#${appElement}`);
  }
  
  const [modalIsOpen, setIsOpen] = React.useState(false);
  
  const openModal = () => {
    setIsOpen(true);
  }
  
  const closeModal = () => {
    setIsOpen(false);
  }

  let strippedString = article.description.childMarkdownRemark.html.replace(/(<([^>]+)>)/gi, "");
  const formNameString = `${article.title} - ${strippedString}`;

  return <div className={styles.preview}>
    <div onClick={openModal}>
      <Img alt="" fluid={article.heroImage.fluid} />
      <h3 className={styles.previewTitle}>{article.title}</h3>
    </div>
    <div
      className={styles.previewSubTitle}
      dangerouslySetInnerHTML={{
        __html: article.description.childMarkdownRemark.html,
      }}
    />
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {}}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <h2>{article.title}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: article.description.childMarkdownRemark.html,
        }}
      />
      <Form 
        id={appElement}
        suburb={article.showSuburb}
        company={article.showCompanyName}
        restaurantLocation={article.showRestaurantLocation}
        formName={formNameString}
      />
    </Modal>
  </div>
}
